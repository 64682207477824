<template lang="pug">
include ../../../configs/template
div.row
  div.col-12
    +select('body.status_document', 'listStatus', 'status', 'nameLang')
  div(v-if="body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.REJECTED").col-12
    Reject
  div(v-if="body.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.REJECTED").col-12
    v-btn(color="success" @click="save" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  name: 'EditStatus',
  components: {
    Reject: () => import('./Reject.vue')
  },
  props: {
    passport: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: null
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    listStatus () {
      let statusesArray = []
      statusesArray = this.$store.getters.statusChoose('StatementSailorPassport')
      statusesArray.push(this.$store.getters.statusById(7))
      statusesArray.push(this.$store.getters.statusById(34))
      statusesArray.push(this.$store.getters.statusById(15))
      statusesArray.push(this.$store.getters.statusById(74))
      return statusesArray
    }
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.passport[key]))
  },
  methods: {
    ...mapActions(['updateSailorForeignPassportById']),
    async save () {
      this.isLoading = true
      const response = await this.updateSailorForeignPassportById({ ...this.$route.params, body: this.body })
      if (SUCCESS_CODE.includes(response.code)) {
        this.$parent.$parent.getData()
        this.$notification.success('internationalPassport.edited')
      }
      this.isLoading = false
    }
  }
}
</script>
